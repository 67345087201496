/* table borders (bootstrap like) */
.ag-theme-balham .ag-root {
  border: 1px solid #dee2e6;
}

/* headers (bootstrap like) */
.ag-theme-balham .ag-header {
  color: #000000;
  /* background-color:#ffc107; */
  background-color: #dae0e5;
  font-weight: bold;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  border-bottom: 1px solid #dee2e6;
}

/* row hover (bootstrap like) */
.ag-row-hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075) !important;
}

/* cells text vertical align center (bootstrap like) */
.ag-row .ag-cell {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  padding-left: 5px;
  padding-right: 5px;
}

.ag-cell {
  white-space: pre-line;
}

/* vertical column divider */
.ag-theme-balham .ag-header-cell::after,
.ag-theme-balham .ag-header-group-cell::after {
  height: 100%;
  margin-top: 0px;
}

/* no cell border when row selected */
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}

/* selected row color */
.ag-theme-balham .ag-row-selected::before {
  color: #155724 !important;
  background-color: #c3e6cb !important;
}

/* selected checkbox color */
.ag-theme-balham .ag-icon-checkbox-checked {
  color: #155724;
}

/* filter popup */
.ag-theme-balham .ag-menu {
  top: 174.4px !important;
}
.ag-header-row .ag-header-cell {
  padding-left: 5px;
  padding-right: 5px;
}
.cell-wrap-text {
  white-space: break-spaces !important;
}
