html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

/* hauteur = reste de la hauteur de la fenêtre */
.fillAvailableHeight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* pour les onglets paramètrables : hauteur tableau = reste de la hauteur */
.tab-content,
.tab-content > .ongletsParametrable.active,
.tab-content > .ongletsParametrable.active {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body {
  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
  background-color: #eeeeee;
}

/* #root {
  padding-top: 65px;
} */

/* onglets bootstrap (tabs) */
.nav.nav-tabs > .nav-item.nav-link {
  background-color: #dae0e5;
  color: #000;
}

.nav.nav-tabs > .nav-item.nav-link:hover {
  background-color: #fff;
}

.nav.nav-tabs > .nav-item.nav-link.active {
  background-color: #fff;
  color: #000;
}
