#backBtn {
  color: rgba(0, 0, 0, 0.5);
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-weight: 500;
  background-color: #ffc107;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 18px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #000;
}

.navbar-light .navbar-nav .nav-link.active {
  border-bottom: 2px solid #000;
  font-weight: 500;
}
